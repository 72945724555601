<template>
  <div class="tvHome">
    <div v-if="!havedata">
      <div class="loadingbox" style="text-align: center;">
        <van-loading color="#f6d0ab" size="34px" />
      </div>
    </div>
    <div v-else class="container-pc">
      <div class="flex-between">
        <div style="margin-left: 1upx;"></div>
        <div style="display: flex;flex-direction: column;text-align: right;">
          <div class="datebox-pc">{{ time }}</div>
          <div class="openbox-pc" :style="{color: opentrade ? '#1cf482' : '#fd2610'}">
            {{ opentrade ? "开盘中" : "已停盘" }}
          </div>
        </div>
      </div>
      <div class="flex" style="margin-top: 2.25rem">
        <div>
          <div class="leftTop">
            <div class="commonTitle leftTopTitle">回购</div>
            <div class="leftTopPrice">
              <div class="leftTopPriceDetail" v-for="(items, indexs) in arrcenter.spotPrice" :key="indexs">
                <div class="goodsName">{{ items.engname }}</div>
                <div class="goodsPrice" :class="items.productData['huigouColor']">
                  {{ items.productData["huigou"] }}
                </div>
              </div>
            </div>
            <div class="commonTitle leftTopTitle">销售</div>
            <div class="leftTopPrice">
              <div class="leftTopPriceDetail" v-for="(items, indexs) in arrcenter.spotPrice" :key="indexs">
                <div class="goodsName">{{ items.engname }}</div>
                <div class="goodsPrice" :class="items.productData['huigouColor']">{{ items.productData["xiaoshou"] }}</div>
              </div>
            </div>
          </div>
          <!-- 左下角 -->
          <div class="leftBottom">
            <div class="flex-between">
              <div v-for="(items, indexs) in percentData" :key="indexs" class="direction-column" style="width: 49%">
                <div class="flex-between leftBottomRow">
                  <div class="leftBottomLeftCommon leftBottomTitle">品类</div>
                  <div class="leftBottomRightCommon leftBottomTitle">含量标准</div>
                </div>
                <div class="flex-between leftBottomRow" v-for="(item, index) in items" :key="index" :style="{marginTop: index === 4 ? '0.19rem' : 0}">
                  <div class="leftBottomLeftCommon leftBottomName">{{ item.name }}</div>
                  <div class="leftBottomRightCommon leftBottomPercent">{{ item.percent }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 右边内容 -->
        <div>
          <div class="rightTop">
            <div class="direction-column">
              <div class="flex-between rightTopRow rightTopTitle commonTitle">
                <div class="rightTopName"></div>
                <div class="rightTopPrice">回购</div>
                <div class="rightTopPrice">销售</div>
              </div>
              <div class="flex-between rightTopRow" v-for="(items, indexs) in arrcenter.shangjinsuo" :key="indexs">
                <div class="rightTopName">{{ items.productData["name"] =='黄金99.99'?'黄金9999':items.productData["name"] =='黄金99.95'?'黄金9995': items.productData["name"].replace("(T+D)", "T+D")}}</div>
                <div class="rightTopPrice" :class="items.productData['huigouColor']">{{ items.productData["huigou"] }}</div>
                <div class="rightTopPrice" :class="items.productData['huigouColor']">{{ items.productData["xiaoshou"] }}</div>
              </div>
            </div>
          </div>
          <div class="rightTop rightBottom">
            <div class="direction-column">
              <div class="flex-between rightTopRow rightTopTitle commonTitle">
                <div class="rightTopName"></div>
                <div class="rightTopPrice">回购</div>
                <div class="rightTopPrice">销售</div>
              </div>
              <template v-for="(items, indexs) in arrcenter.guojixianhuo" >
                <div class="flex-between rightTopRow" :key="indexs" v-if="[0, 1, 5, 6].includes(indexs)">
                  <div class="rightTopName">{{ items.productData["name"] }}</div>
                  <div class="rightTopPrice" :class="items.productData['huigouColor']">{{ items.productData["huigou"] }}</div>
                  <div class="rightTopPrice" :class="items.productData['huigouColor']">{{ items.productData["xiaoshou"] }}</div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Stomp from "stompjs";

export default {
  data() {
    return {
      havedata: false,
      percentData: [
        [{name: "黄金板料", percent: "100%"}, {name: "黄金金条999", percent: "99.9%"}, {name: "黄金首饰999", percent: "99.85%"}, {name: "黄金18K 750", percent: "74.8%"}, {name: "钯金板料", percent: "100%"}, {name: "钯金首饰999", percent: "98%"}, {name: "钯金首饰990", percent: "97%"}, {name: "钯金首饰950", percent: "93%"}],
        [{name: "铂金板料", percent: "100%"}, {name: "铂金首饰999", percent: "99.2%"}, {name: "铂金首饰990", percent: "98.2%"}, {name: "铂金首饰950", percent: "94.6%"}, {name: "白银板料", percent: "100%"}, {name: "白银首饰999", percent: "98%"}, {name: "白银首饰990", percent: "97%"}, {name: "白银首饰925", percent: "91%"}],
      ],
      isOpenSocket: false, // 确保websocket是打开状态
      client: null, // 当前stomp连接
      opentrade: false, // 开盘状态
      time: null, // 当前时间
      week: "", // 当前周几
      onedata: [],
      // 深度克隆后的赋值
      updataarr: [],
      firstload: true,
      wsPrice: null,
      arrcenter: {
        huangjin: [],
        bojin: [],
        bajin: [],
        baiyin: [],
        shangjinsuo: [],
        guojixianhuo: [],
        // 现货价格
        spotPrice:[]
      },
      new_tuisong_val_last: {},
      new_tuisong_val_before: {},
    }
  },
  watch: {
    onedata: function(newN, oldN) {
      const than = this;

      // console.log(newN);
      Object.entries(newN).forEach(([typeKey, type]) => {
        if (["time", "trade", "id"].includes(typeKey)) {
          return;
        }
        Object.entries(type.prices).forEach(([goodsKey, goods]) => {
          //  debugger
          let huigouColor = "redColor";
          if (oldN && !Array.isArray(oldN)) {
            const oldGoods = oldN[typeKey].prices[goodsKey];
            const oldHuigou = oldGoods["huigou"];
            const newHuigou = goods["huigou"];
            const oldHuigouColor = oldGoods["huigouColor"];
            // console.log(newHuigou, oldHuigou);
            // console.log(oldHuigouColor);
            // debugger
            huigouColor =
              newHuigou < oldHuigou
                ? "greenColor"
                : newHuigou == oldHuigou
                  ? oldHuigouColor
                  : "redColor";
          }
          goods["huigouColor"] = huigouColor;
        });
      });
      if (oldN instanceof Array) {
      } else {
        this.new_tuisong_val_before = JSON.parse(JSON.stringify(oldN));
        this.new_tuisong_val_last = JSON.parse(JSON.stringify(newN));
        this.newlistsort(this.new_tuisong_val_last);
      }
    }
  },
  mounted() {
    // 不是电脑端跳转到首页
    console.log(window.screen.width / window.screen.height);
    if (window.screen.width / window.screen.height < 1) {
      this.$router.push({
        name: "home"
      });
    }
    if (!this.isOpenSocket || this.client == null) {
      this.connect();
    }
  },
  methods: {
    connect() {
      this.isOpenSocket = true;
      this.client = Stomp.over(new WebSocket(process.env.VUE_APP_WS));
      // ---初始化mqtt客户端，并连接mqtt服务
      const headers = {
        login: "username",
        passcode: "password"
      };
      this.client.connect(headers, this.onConnected, this.onFailed);
      this.client.debug = null;
    },
    onConnected() {
      const topic = "/price/all";
      this.client.subscribe(topic, this.responseCallback, this.onFailed);
    },
    onFailed(frame) {
      console.log("错误: " + frame);
      this.client = Stomp.over(new WebSocket(process.env.VUE_APP_WS));
      if (!this.client.connected) {
        this.opentrade = false;
        setTimeout(() => {
          this.connect();
        }, 1000);
      }
    },
    responseCallback(frame) {
      const than = this;
      const res_data = eval("[" + this.unzip(frame.body) + "]")[0];
      const parse_res = JSON.parse(JSON.stringify(res_data));
      if (!this.firstload) {
        than.onedata = than.deepMerge(than.wsPrice, parse_res);
        // --------------------
        than.wsPrice = than.onedata;
        // --------------------
      } else {
        than.firstload = false;
        than.updataarr = parse_res;
        than.wsPrice = parse_res;
      }
      //  是否开盘中
      this.opentrade = parse_res.trade;
      let resbody = "";
      if (JSON.stringify(than.new_tuisong_val_last) == "{}") {
        resbody = than.updataarr;
        this.newlistsort(resbody);
      } else {
      }
      this.havedata = true;
    },
    unzip(b64Data) {
      let strData = atob(b64Data);
      const charData = strData.split("").map(function(x) {
        return x.charCodeAt(0);
      });
      const binData = new Uint8Array(charData);
      const data = pako.inflate(binData);
      strData = String.fromCharCode.apply(null, new Uint16Array(data));
      return decodeURIComponent(escape(strData));
    },
    deepMerge(...objs) {
      const than = this;
      const result = Object.create(null);
      objs.forEach(obj => {

        if (obj) {
          Object.keys(obj).forEach(key => {
            const val = obj[key];
            if (than.isPlainObject(val)) {
              // 递归
              if (than.isPlainObject(result[key])) {
                result[key] = than.deepMerge(result[key], val);
              } else {
                result[key] = than.deepMerge(val);
              }
            } else {
              //  数组也要重新赋值  不然依然会引用到其他的
              if (Array.isArray(val)) {
                result[key] = [...val];
              } else {
                result[key] = val;
              }
            }
          });
        }
      });
      return result;
    },
    isPlainObject(val) {
      const toString = Object.prototype.toString;
      return toString.call(val) === "[object Object]";
    },
    newlistsort(data) {
      const than = this;
      than.arrcenter.huangjin = [];
      than.arrcenter.bojin = [];
      than.arrcenter.bajin = [];
      than.arrcenter.baiyin = [];
      than.arrcenter.guojixianhuo = [];
      than.arrcenter.shangjinsuo = [];
      than.arrcenter.spotPrice =[]
      const resbody = data;
      console.log("resbody:", resbody)
      for (const i in resbody) {
        console.log("i:", i)
        if (i === 'time') {
          this.time = this.timestampToTime(resbody[i]);
        }
        if (resbody[i].name === "黄金") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.huangjin.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b]
              });

            }
          }

          this.arrcenter.huangjin.sort(this.compare("sort"));
          // this.productall.huangjin = resbody[i].prices
        }
        if (resbody[i].name === "铂金") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.bojin.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b]
              });
            }
          }
          this.arrcenter.bojin.sort(this.compare("sort"));
        }
        if (resbody[i].name === "钯金") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.bajin.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b]
              });
            }
          }
          this.arrcenter.bajin.sort(this.compare("sort"));
        }
        if (resbody[i].name === "白银") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.baiyin.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b],
                time: resbody.time
              });
            }
          }
          this.arrcenter.baiyin.sort(this.compare("sort"));
        }
        if (resbody[i].name === "上金所") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.shangjinsuo.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b]
              });
            }
          }

          this.arrcenter.shangjinsuo.sort(this.compare("sort"));
        }
        if (resbody[i].name === "国际现货") {
          for (const b in resbody[i].prices) {
            if (resbody[i].prices[b]["display"] == "SHOW") {
              this.arrcenter.guojixianhuo.push({
                engname: b,
                sort: resbody[i].prices[b].sort,
                productData: resbody[i].prices[b]
              });
            }
          }
          this.arrcenter.guojixianhuo.sort(this.compare("sort"));
        }
      }


      let res = 0
      this.arrcenter.huangjin.forEach((item,i)=>{
        if (item.engname  == "huangjin9999") {
          res = item.productData.huigou
          this.arrcenter.spotPrice.push({
            engname: '黄金',
            productData: item.productData
          })
        }
      })
      let res1 = 0
      this.arrcenter.baiyin.forEach((item,i)=>{
        if (item.engname  == "baiyin9999") {
          res1 = item.productData.huigou
          this.arrcenter.spotPrice.push({
            engname: '白银',
            productData: item.productData
          })
        }
      })
      let res2 = 0
      this.arrcenter.bojin.forEach((item,i)=>{
        if (item.engname == "bojin9996") {
          res2 = item.productData.huigou
          this.arrcenter.spotPrice.push({
            engname: '铂金',
            productData: item.productData
          })
        }
      })
      let res3 = 0
      this.arrcenter.bajin.forEach((item,i)=>{
        if (item.engname == "bajin9996") {
          res3 = item.productData.huigou

          this.arrcenter.spotPrice.push({
            engname: '钯金',
            productData: item.productData
          })
        }
      })
    },
    // 排序
    compare(property) {
      return function(a, b) {
        const value1 = a[property];
        const value2 = b[property];
        return value1 - value2;
      };
    },
    timestampToTime(timestamp) {
      timestamp = timestamp ? timestamp : null;
      const date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      const Y = date.getFullYear() + "-";
      const M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      const D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      const h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      const m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      const s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      const a = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
      this.week = a[date.getDay()];
      return Y + M + D + h + m + s;
    }
  }
}
</script>

<style lang="scss" scoped="true">
.tvHome {
  background: #141414;
  height: 100%;
  .loadingbox {
    padding-top: 40rem;
  }
  .container-pc {
    height: 100%;
    width: 100%;
    background: #0a0a09;
    position: fixed;
    background-image: url("~@/assets/images/tvBg_as.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    padding-left: 6.25rem;
    padding-right: 6.25rem;
    // 盘面状态
    .openbox-pc {
      color: #ed1818;
      font-size: 2rem;
    }
    //时间
    .datebox-pc {
      opacity: 0.8;
      color: #b3b3b3;
      font-size: 1.63rem;
      margin-top: 2.63rem;
    }
    .leftTop {
      width: 60.56rem;
      height: 24.87rem;
      text-align: center;
      .leftTopTitle {
        background: #191919;
      }
      .leftTopPrice {
        background: #222222;
        display: flex;
        justify-content: space-between;
        padding: 1.5rem 6rem;
        .leftTopPriceDetail {
          .goodsName {
            font-size: 1.88rem;
            color: #cab08a;
          }
          .goodsPrice {
            font-size: 2.5rem;
          }
        }
      }
    }
    .leftBottom {
      width: 60.56rem;
      height: 29.25rem;
      margin-top: 1.25rem;
      .leftBottomRow {
        height: 3.25rem;
        line-height: 3.25rem;
        background: #111111;
        &:nth-child(even) {
          background: #222222;
        }
        .leftBottomLeftCommon {
          margin-left: 5rem;
          text-align: left;
        }
        .leftBottomRightCommon {
          margin-right: 5rem;
          text-align: right;
        }
        .leftBottomTitle {
          color: #666666;
          font-size: 1.25rem;
        }
        .leftBottomName {
          color: #999999;
          font-size: 1.5rem;
        }
        .leftBottomPercent {
          color: #cab08a;
          font-size: 1.5rem;
        }
      }
    }

    .rightTop {
      margin-left: 1.25rem;
      width: 45.69rem;
      height: 27.25rem;
      text-align: center;
      .rightTopRow {
        background: #111111;
        padding: 1.5rem 5rem 1.5rem 0;
        &:nth-child(even) {
          background: #222222;
        }
        .rightTopName {
          font-size: 1.88rem;
          color: #999999;
          margin-left: 5.31rem;
          width: 8.25rem;
        }
        .rightTopPrice {
          font-size: 2.13rem;
          width: 7rem;
          text-align: center;
          //margin-left: 7rem;
        }
      }
      .rightTopTitle {
        padding: 0 5rem 0 0 !important;
      }
    }
    .rightBottom {
      margin-top: 1.25rem;
    }
  }
  .commonTitle {
    color: #666666;
    background: #191919 !important;
    font-size: 14px;
    height: 3.75rem;
    line-height: 3.75rem;
  }
  .greenColor {
    color: #1cf482;
  }
  .redColor {
    color: #fd2610;
  }
  /* 两端对齐 */
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  .direction-row {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .direction-column {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
</style>
