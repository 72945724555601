var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tvHome" }, [
    !_vm.havedata
      ? _c("div", [
          _c(
            "div",
            {
              staticClass: "loadingbox",
              staticStyle: { "text-align": "center" }
            },
            [_c("van-loading", { attrs: { color: "#f6d0ab", size: "34px" } })],
            1
          )
        ])
      : _c("div", { staticClass: "container-pc" }, [
          _c("div", { staticClass: "flex-between" }, [
            _c("div", { staticStyle: { "margin-left": "1upx" } }),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "column",
                  "text-align": "right"
                }
              },
              [
                _c("div", { staticClass: "datebox-pc" }, [
                  _vm._v(_vm._s(_vm.time))
                ]),
                _c(
                  "div",
                  {
                    staticClass: "openbox-pc",
                    style: { color: _vm.opentrade ? "#1cf482" : "#fd2610" }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.opentrade ? "开盘中" : "已停盘") + " "
                    )
                  ]
                )
              ]
            )
          ]),
          _c(
            "div",
            { staticClass: "flex", staticStyle: { "margin-top": "2.25rem" } },
            [
              _c("div", [
                _c("div", { staticClass: "leftTop" }, [
                  _c("div", { staticClass: "commonTitle leftTopTitle" }, [
                    _vm._v("回购")
                  ]),
                  _c(
                    "div",
                    { staticClass: "leftTopPrice" },
                    _vm._l(_vm.arrcenter.spotPrice, function(items, indexs) {
                      return _c(
                        "div",
                        { key: indexs, staticClass: "leftTopPriceDetail" },
                        [
                          _c("div", { staticClass: "goodsName" }, [
                            _vm._v(_vm._s(items.engname))
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "goodsPrice",
                              class: items.productData["huigouColor"]
                            },
                            [
                              _vm._v(
                                " " + _vm._s(items.productData["huigou"]) + " "
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  ),
                  _c("div", { staticClass: "commonTitle leftTopTitle" }, [
                    _vm._v("销售")
                  ]),
                  _c(
                    "div",
                    { staticClass: "leftTopPrice" },
                    _vm._l(_vm.arrcenter.spotPrice, function(items, indexs) {
                      return _c(
                        "div",
                        { key: indexs, staticClass: "leftTopPriceDetail" },
                        [
                          _c("div", { staticClass: "goodsName" }, [
                            _vm._v(_vm._s(items.engname))
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "goodsPrice",
                              class: items.productData["huigouColor"]
                            },
                            [_vm._v(_vm._s(items.productData["xiaoshou"]))]
                          )
                        ]
                      )
                    }),
                    0
                  )
                ]),
                _c("div", { staticClass: "leftBottom" }, [
                  _c(
                    "div",
                    { staticClass: "flex-between" },
                    _vm._l(_vm.percentData, function(items, indexs) {
                      return _c(
                        "div",
                        {
                          key: indexs,
                          staticClass: "direction-column",
                          staticStyle: { width: "49%" }
                        },
                        [
                          _vm._m(0, true),
                          _vm._l(items, function(item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "flex-between leftBottomRow",
                                style: {
                                  marginTop: index === 4 ? "0.19rem" : 0
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "leftBottomLeftCommon leftBottomName"
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "leftBottomRightCommon leftBottomPercent"
                                  },
                                  [_vm._v(_vm._s(item.percent))]
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    }),
                    0
                  )
                ])
              ]),
              _c("div", [
                _c("div", { staticClass: "rightTop" }, [
                  _c(
                    "div",
                    { staticClass: "direction-column" },
                    [
                      _vm._m(1),
                      _vm._l(_vm.arrcenter.shangjinsuo, function(
                        items,
                        indexs
                      ) {
                        return _c(
                          "div",
                          {
                            key: indexs,
                            staticClass: "flex-between rightTopRow"
                          },
                          [
                            _c("div", { staticClass: "rightTopName" }, [
                              _vm._v(
                                _vm._s(
                                  items.productData["name"] == "黄金99.99"
                                    ? "黄金9999"
                                    : items.productData["name"] == "黄金99.95"
                                    ? "黄金9995"
                                    : items.productData["name"].replace(
                                        "(T+D)",
                                        "T+D"
                                      )
                                )
                              )
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "rightTopPrice",
                                class: items.productData["huigouColor"]
                              },
                              [_vm._v(_vm._s(items.productData["huigou"]))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "rightTopPrice",
                                class: items.productData["huigouColor"]
                              },
                              [_vm._v(_vm._s(items.productData["xiaoshou"]))]
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ]),
                _c("div", { staticClass: "rightTop rightBottom" }, [
                  _c(
                    "div",
                    { staticClass: "direction-column" },
                    [
                      _vm._m(2),
                      _vm._l(_vm.arrcenter.guojixianhuo, function(
                        items,
                        indexs
                      ) {
                        return [
                          [0, 1, 5, 6].includes(indexs)
                            ? _c(
                                "div",
                                {
                                  key: indexs,
                                  staticClass: "flex-between rightTopRow"
                                },
                                [
                                  _c("div", { staticClass: "rightTopName" }, [
                                    _vm._v(_vm._s(items.productData["name"]))
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "rightTopPrice",
                                      class: items.productData["huigouColor"]
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(items.productData["huigou"])
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "rightTopPrice",
                                      class: items.productData["huigouColor"]
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(items.productData["xiaoshou"])
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  )
                ])
              ])
            ]
          )
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-between leftBottomRow" }, [
      _c("div", { staticClass: "leftBottomLeftCommon leftBottomTitle" }, [
        _vm._v("品类")
      ]),
      _c("div", { staticClass: "leftBottomRightCommon leftBottomTitle" }, [
        _vm._v("含量标准")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flex-between rightTopRow rightTopTitle commonTitle" },
      [
        _c("div", { staticClass: "rightTopName" }),
        _c("div", { staticClass: "rightTopPrice" }, [_vm._v("回购")]),
        _c("div", { staticClass: "rightTopPrice" }, [_vm._v("销售")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flex-between rightTopRow rightTopTitle commonTitle" },
      [
        _c("div", { staticClass: "rightTopName" }),
        _c("div", { staticClass: "rightTopPrice" }, [_vm._v("回购")]),
        _c("div", { staticClass: "rightTopPrice" }, [_vm._v("销售")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }